<template>
  <div>
    {{ result }}
    <v-btn @click="execute()">パーツの画像パスを登録する</v-btn>
  </div>
</template>

<script>
import {RepositoryFactory} from '../../api/RepositoryFactory'

export default {
  data() {
    return {
      result: null,
    }
  },

  methods: {
    async execute() {
      const SectionRepository = RepositoryFactory.get( 'section' );
      const response = await SectionRepository.saveImagePath();
      if (response.data.result && response.data.resultCode === '0000') {
        console.log(response.data);
        this.result = response.data;
      } else {
        this._sendError( response.data.message );
      }
    }
  }
}
</script>